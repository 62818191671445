import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

(window as any).api &&
	(window as any).api.receive("environment-updated", async (environment: string) => {
		localStorage.setItem("env", environment);
		window.location.hash = "";
		window.location.reload();
	});

(window as any).api &&
	(window as any).api.receive("get-token", async (token: string) => {
		localStorage.setItem("nucleus-jwt-token", token);
	});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);
